
import AddToCart from '~/components/product/AddToCart.vue'
export default {
  name: 'ProductPageCard',
  props: ['item','buy','filter', 'show_all', 'extraClass'],
  components: { AddToCart },
  data() {
    return {
      isLoading: true,
      product: false,
      sku: false
    }
  },
  computed: {
   ccProduct(){
      return this.$store.getters.product(this.sku)
    },
    hideVariants() {
      return this.sku === 'SH00AA00' || this.sku === 'NT01AA02'
    }
  },
  methods: {
    setVariant(v, variants){
      this.product = {
        uid: this.product.uid,
        name: !this.show_all ? v.v_name : this.product.name,
        image: v.v_image,
        description: !this.show_all ? v.v_description : this.product.description,
        variants
      }
      this.sku = v.v_sku
    }
    // initIntersection(id){
    //   const _self = this
    //   const target = document.getElementById(id)
    //   const observer = new IntersectionObserver(_self.handleIntersection)
    //   target ? observer.observe(target) : observer.disconnect()
    // },
    // handleIntersection(entries) {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       // Product Impression
    //     }
    //   })
    // }
  },
  async mounted() {
    const lang = this.$i18n.locales.filter((i) => i.iso.includes(this.$i18n.locale))[0].iso
    try {
      const response = await this.$prismic.api.query(this.$prismic.predicates.at(`my.${this.item.type}.uid`, this.item.uid), { lang })
      const filter = this.filter || this.item.filter
      const showAll = this.show_all || this.item.show_all
      if (filter) {
        const productPage = {...this.item, ...response.results[0].data}
        const variants = productPage.variants.filter(v => v.v_group === filter)
        this.setVariant(variants[0], variants)
      } else {
        this.product = {...this.item, ...response.results[0].data}
        this.sku = this.product?.variants?.[0]?.v_sku
      }
      if (showAll) {
        this.product.name = response.results[0].data.name 
        this.product.description = response.results[0].data.description 
        this.product.uid = this.item.uid
        // = {...response.results[0].data, uid: this.item.uid}
      }
      this.isLoading = false
    } catch (e) {
      console.log({ statusCode: 404, message: 'Product Page not found' })
    }

    // const cardId = `product-card-${this.item.uid}`
    // if (document.getElementById(cardId)) { this.initIntersection(cardId) }
  },
}
