import { render, staticRenderFns } from "./ProductPageCard.vue?vue&type=template&id=49fe4195&lang=pug"
import script from "./ProductPageCard.vue?vue&type=script&lang=js"
export * from "./ProductPageCard.vue?vue&type=script&lang=js"
import style0 from "./ProductPageCard.vue?vue&type=style&index=0&id=49fe4195&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtLinkCustom: require('/opt/build/repo/components/NuxtLinkCustom.vue').default})
